const styles = ({ colors, breakpoints }) => ({
  container: {
    marginTop: 50,
  },
  line: {
    display: 'inline-block',
    backgroundColor: colors.themeLightGray,
    width: '127px',
    height: '1px',
    textAlign: 'center',
  },
  title: {
    marginTop: 32,
    marginBottom: 24,
  },
  cardContainer: {
    padding: '0 8px',
  },
  card: {
    minHeight: '390px',
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      minHeight: '300px',
    },
  },
  cardIcon: {
    width: 'auto',
    height: 64,
    color: colors.expandedIconBlack,
  },
  cardTitle: {
    margin: '24px 0',
  },
  cardContent: {
    marginBottom: 24,
  },
  cardButton: {
    marginTop: 8,
    marginBottom: 8,
  },
  cardBottom: {
    position: 'absolute',
    bottom: 8,
    left: 0,
  },
  cardButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > svg': {
      marginRight: 10,
    },
  },
  messagesContainer: {
    width: '100%',
    borderTop: `1px solid ${colors.themeLightGray}`,
    paddingTop: 16,
    textAlign: 'left',
  },
  message: {
    color: '#747474',
    fontSize: '10px',
    lineHeight: '15px',
  },
});

export default styles;
