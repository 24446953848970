import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import injectSheet from 'react-jss';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { startsWith } from 'lodash';
import InfoIcon from '@eg/elements/Icons/InfoIcon';
import MessageBox from '@eg/elements/MessageBox';
import Details from '@eg/elements/Details';
import ProfileIcon from '@eg/elements/Icons/ProfileIcon';
import Card from '@eg/elements/Card';
import Input from '@eg/elements/Input';
import ArrowDownIcon from '@eg/elements/Icons/ArrowDownIcon';
import Autocomplete from '@eg/elements/Autocomplete';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import { types } from '../../actions/constants';
import HeadingForm from '../../layout/HeadingForm';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ErgoNavButton from '../../components/ErgoNavButton';
import FormMessage from '../../components/FormMessage';
import { getPolicyNumber } from '../../helpers/formData/collisionAbroadFormData';
import styles from './styles.jss';

const CollisionAbroadInsurerSelector = ({
  classes, localePath, pathName, getValidationProps, isMobile,
}, { t }) => {
  const {
    name, onChange, error,
  } = getValidationProps();

  const dispatch = useDispatch();
  const currentValue = useSelector(state => {
    const { formData } = state;
    const questionPath = localePath.split('.')[1];
    return formData[pathName] ? formData[pathName][questionPath] : '';
  }, shallowEqual);
  const [insurer, setInsurer] = useState(currentValue);
  const [initSuggestions, setInitSuggestions] = useState(() => Object.keys(getPolicyNumber()));
  const [currentSuggestions, setCurrtentSuggestions] = useState(initSuggestions);
  const [errorMessage, setErrorMessage] = useState(false);

  const setChanges = (payload) => {
    setInsurer(payload);
    dispatch({
      type: types.FIELD_UPDATE,
      fieldName: name,
      payload,
      pathName,
    });
    onChange(name)(payload);
  };

  useEffect(() => (
    !initSuggestions.includes(insurer) ? setErrorMessage(true) : setErrorMessage(false)
  ), [insurer]);

  const getInputWithArrow = (props) => (
    <Input
      {...props}
      // eslint-disable-next-line react/prop-types
      ref={el => el && props.ref(el.input)}
      adornmentRight={ArrowDownIcon}
    />
  );

  const searchSuggestions = search => {
    const suggestions = initSuggestions.filter(item => startsWith(item.toLowerCase(), search.toLowerCase()));
    setCurrtentSuggestions(suggestions);
    setChanges(search);
  };

  return (
    <ContentWrapper>
      {isMobile ? (
        <Details
          iconLeft={InfoIcon}
          className={classes.mobileAccordion}
          summary={(
            <span className={classes.mobileAccordionTitle}>
              {t(`${localePath}.privacyInfoShowMore`)}
            </span>
          )}
        >
          {t(`${localePath}.legalText`)}
          <a
            href="https://www.ergo.de/de/Service/Datenschutz"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            {t(`${localePath}.legalTextLink`)}
          </a>
          .
        </Details>
      ) : (
        <MessageBox className={classes.messageBox}>
          <InfoIcon width={32} height={32} className={classes.infoIcon} />
          <div className={classes.messageBoxContent}>
            {t(`${localePath}.legalText`)}
            <a
              href="https://www.ergo.de/de/Service/Datenschutz"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              {t(`${localePath}.legalTextLink`)}
            </a>
            .
          </div>
        </MessageBox>
      )}
      <div className="esc_grid">
        <div className="esc_col esc_col-12 esc_col-l-8 esc_col-l--offset-2">
          <Card className={classes.card}>
            <div className={classes.cardHeader}>
              {t(`${localePath}.cardTitle`)}
            </div>
            <div className={`esc_grid ${classes.cardContent}`}>
              <div className="esc_col esc_col-12 esc_col-l-12">
                <div className="esc_grid__wrapper">
                  <div className="esc_col esc_col-12 esc_col-l-12">
                    <ProfileIcon width="45px" height="45px" className={classes.cardIcon} />
                  </div>
                </div>
                <div className="esc_col esc_col-12 esc_col-l-12">
                  {t(`${localePath}.cardText`)}
                </div>
                <div className="esc_col esc_col-12 esc_col-l-12">
                  <Autocomplete
                    className={classes.autocomplete}
                    value={insurer}
                    name={name}
                    onChange={searchSuggestions}
                    suggestions={currentSuggestions}
                    renderInput={getInputWithArrow}
                  />
                  <FormMessage
                    error={error}
                    description={t(`${localePath}.description`)}
                  />
                </div>
                <div style={{ marginTop: '48px' }}>
                  <ErgoNavButton
                    disabled={!!error || errorMessage}
                    title="button.next"
                    size="normal"
                    width="fix"
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </ContentWrapper>
  );
};

CollisionAbroadInsurerSelector.propTypes = {
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  getValidationProps: PropTypes.func,
  classes: PropTypes.objectOf(Object),
  isMobile: PropTypes.bool,
};

CollisionAbroadInsurerSelector.defaultProps = {
  localePath: '',
  pathName: '',
  classes: {},
  getValidationProps: () => { },
  isMobile: false,
};

CollisionAbroadInsurerSelector.contextTypes = {
  t: PropTypes.func,
};

export { CollisionAbroadInsurerSelector as CollisionAbroadInsurerSelectorPlain };

export default compose(
  withSingleFieldValidation,
  injectSheet(styles),
)(CollisionAbroadInsurerSelector);
